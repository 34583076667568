<template>
    <div class="px-2 mt-8 produkKami lg:px-32 ">
        <div class="mb-10 text-left">
            <h1 class="mb-5 text-3xl font-semibold"> {{ $t('product.title') }}</h1>
            <div v-html="prodLang" class="text-base"></div>
        </div>
       
        <!-- <ul class="flex items-center border-b-2 border-secondary">
            <li 
                class="flex items-center justify-center h-10 px-2 ml-0 mr-8 text-sm font-bold capitalize border rounded-sm cursor-pointer bg-border text-secondary border-secondary md:mr-8 font-pop md:text-base w-36 hover:bg-secondary hover:text-grays-50"
                v-for="(item, index) in dataTab" :key="index"
                :class="{'activeTabMen' : index == tabActive }"
                @click="handleTab(index)"
                v-if="lang == 'id'"
            >
                {{item.tab}}
            </li>

            <li 
                class="flex items-center justify-center h-10 px-2 ml-0 mr-8 text-sm font-bold capitalize border rounded-sm cursor-pointer bg-border text-secondary border-secondary md:mr-8 font-pop md:text-base w-36 hover:bg-secondary hover:text-grays-50"
                v-for="(item, index) in dataTab_Eng" :key="index"
                :class="{'activeTabMen' : index == tabActive }"
                @click="handleTab(index)"
                v-if="lang !== 'id'"
            >
                {{item.tab}}
            </li>
            
        </ul>

        <section class="mb-20">
            <div  v-if="tabActive == 0">
                <card 
                    :dtCard="dt2.data"
                    :lang="lang"
                    :dtDrop="dtProdType"
                    :tabActive="tabActive"
                    dType="2w"
                />

                

                <div class="grid mt-20 place-items-center" v-if="dt2.meta.total !== dt2.data.length">
                    <div 
                        class="flex items-center justify-center text-sm uppercase border cursor-pointer w-36 text-links h-11 border-links"
                        @click="moreTab(0,20)"
                    >
                        <span v-if="lang == 'id'">Muat Lainnya</span>
                        <span v-else>More</span>
                    </div>
                </div>

            </div>

            <div  v-if="tabActive == 1">
                <card 
                    :dtCard="dt3.data"
                    :lang="lang"
                    :dtDrop="dtProdType"
                    :tabActive="tabActive"
                    dType="3w"
                />

                <div class="grid mt-20 place-items-center" v-if="dt3.meta.total !== dt3.data.length">
                    <div 
                        @click="moreTab(1,20)"
                        class="flex items-center justify-center text-sm uppercase border cursor-pointer w-36 text-links h-11 border-links">
                        <span v-if="lang == 'id'">Muat Lainnya</span>
                        <span v-else>More</span>
                    </div>
                </div>
            </div>

            <div  v-if="tabActive == 2">
                <card 
                    :dtCard="dt4.data"
                    :lang="lang"
                    :dtDrop="dtProdType"
                    :tabActive="tabActive"
                    dType="4w"
                />

                <div class="grid mt-20 place-items-center" v-if="dt4.meta.total !== dt4.data.length">
                    <div 
                        class="flex items-center justify-center text-sm uppercase border cursor-pointer w-36 text-links h-11 border-links"
                        @click="moreTab(2,20)"
                    >
                        <span v-if="lang == 'id'">Muat Lainnya</span>
                        <span v-else>More</span>
                    </div>
                </div>
            </div>

            <div  v-if="tabActive == 4">
                <card 
                    :dtCard="dtother.data"
                    :lang="lang"
                    :dtDrop="dtProdType"
                    dType="other"
                    :tabActive="tabActive"
                />

                <div class="grid mt-20 place-items-center" v-if="dtother.meta.total !== dtother.data.length">
                    <div
                        @click="moreTab(3,20)" 
                        class="flex items-center justify-center text-sm uppercase border cursor-pointer w-36 text-links h-11 border-links">
                        <span v-if="lang == 'id'">Muat Lainnya</span>
                        <span v-else>More</span>
                    </div>
                </div>
            </div>
            
        </section>
         -->
     

        <div class="flex flex-wrap items-center justify-start w-full pb-3 border-b gap-x-10 gap-y-3 border-secondary font-pop">
            <div 
               
               class="flex flex-wrap items-center justify-center h-full pb-2 text-xs font-semibold capitalize duration-100 cursor-pointer font-pop lg:text-sm text-grays-400 border-grays-200 hover:border-secondary hover:text-secondary"

                v-for="(item, index) in listCategory" :key="index"
                :class="{'activeTabMen' : index == tabActive }"
                @click="listSelect(index, item)"
                style="min-width: max-content;"
            >
                {{lang == 'id' ? item.name: item.name_eng}}
        </div>
          </div>

        <section class="mb-20" v-if="dtProduk.length !== 0">
           
            <card 
                :dtCard="dtProduk.data"
                :lang="lang"
                :dtDrop="dtProdType"
                :tabActive="tabActive"
                :dType="this.tabNameActive 
"
            />

            
            <div class="grid mt-20 place-items-center" v-if="dtProduk.meta.total !== dtProduk.data.length">
                <div 
                    class="flex items-center justify-center text-sm uppercase border cursor-pointer w-36 text-links h-11 border-links"
                    @click="lainNya(dtProduk.data,20)"
                >
                    <span v-if="lang == 'id'">Muat Lainnya</span>
                    <span v-else>More</span>
                </div>
            </div>

            
        </section>

        <div v-else  class="mb-20"></div>
            
        
    </div>
</template>

<script>
import { mapState } from 'vuex';
import home from '../store/modules/home';

export default {
    components:{
        card : () => import ("@/components/produk/card")
    },
    data() {
        return {
            tabActive: 0,
            dataTab:[
                {tab : "Roda 2"},
                {tab : "Roda 3"},
                {tab : "Roda 4"},
                {tab : "Fastener"},
                {tab : "Lainnya"}
            ],
            dataTab_Eng:[
                {tab : "2 wheels"},
                {tab : "3 wheels"},
                {tab : "4 wheels"},
                {tab : "Fastener"},
                {tab : "Others"}
            ],
            counter : 0,
            tabNameActive: 0
        }
    },
    computed: {
        ...mapState({
            dt2: state => state.produk.dt2w ? state.produk.dt2w :'',
            dt3: state => state.produk.dt3w ? state.produk.dt3w :'',
            dt4: state => state.produk.dt4w ? state.produk.dt4w :'',
            dtother: state => state.produk.dtother ? state.produk.dtother :'',
            lang: state => state.home.lang ? state.home.lang :'id',
            dtProdType: state => state.produk.typeProd ? state.produk.typeProd.data :'',
            data: state => state.home.home ? state.home.home.data :'',
            listCategory : state => state.produk.listCategory? state.produk.listCategory.data :[],
            dtProduk: state => state.produk.dtProduk? state.produk.dtProduk :[]

        }),
        prodLang(){
			let prod_id = {}
			let prod_eng= {}

			if (this.lang == 'id'){
				Object.assign(prod_id, 
					{product: this.data.product}
				);
				return prod_id.product
			}else{
				Object.assign(prod_eng, 
					{product: this.data.product_eng}
				);
				return prod_eng.product
			}
		},
       
        dtProd22(){
            return this.$store.getters['produk/withFilter']('2w','Suport Engine')
        },
        
    },
    mounted() {
     
        
    },
    methods: {
        async handleTab(i){
            this.tabActive = i

            try {
                if(i == 0){
                    const dt2w = {type:'2w'}
                    await this.$store.dispatch('produk/getDtProd', dt2w)
                }else if(i == 1){
                    const dt3w = {type:'3w'}
                    await this.$store.dispatch('produk/getDtProd', dt3w)
                }else if(i == 2){
                    const dt4w = {type:'4w'}
                    await this.$store.dispatch('produk/getDtProd', dt4w)
                }else if(i == 4){
                    const other = {type:'other'}
                    await this.$store.dispatch('produk/getDtProd', other)
                }else if(i == 3){
                    // window.location.href = 'https://www.google.com'
                    window.open('http://fastbolt.dharmap.com/about-us', '_blank');
                   
                }
				
            } catch (error) {
                console.log(error)
            }
        },

        async moreTab(i, incre){
           
            try {
            
                if(i == 0){
                    const dt2w = {type:'2w', perpage:  this.counter += incre}
                    await this.$store.dispatch('produk/getDtProd', dt2w)
                }else if(i == 1){
                    const dt3w = {type:'3w', perpage:  this.counter += incre}
                    await this.$store.dispatch('produk/getDtProd', dt3w)
                }else if(i == 2){
                    const dt4w = {type:'4w', perpage:  this.counter += incre}
                    await this.$store.dispatch('produk/getDtProd', dt4w)
                }else if(i == 4){
                    const other = {type:'other', perpage:  this.counter += incre}
                    await this.$store.dispatch('produk/getDtProd', other)
                }
				
            } catch (error) {
                console.log(error)
            }
        },

        async lainNya(item, incre){
          
            try {
                const payload= {type:item[0].category_id , perpage:  this.counter += incre}
                await this.$store.dispatch("produk/getCategoryBy", payload);
            } catch (error) {
                console.log(error);
            }
        },

        async listSelect(index, item){
            this.tabActive = index
            this.tabNameActive = item ?item.id:0

            if(item?.is_redirect == 1){
                window.open(item?.redirect_url, '_blank');
                try {
                    const payload= {type:item? item.name : 'Roda 100'  , perpage:  20}
                    await this.$store.dispatch("produk/getCategoryBy", payload);
                } catch (error) {
                    console.log(error);
                }

                // console.log(  this.$store.state.produk.dtProduk )
            }else{
             
                try {
                    const payload= {type:item? item.id : 0  , perpage:  20}
                    await this.$store.dispatch("produk/getCategoryBy", payload);
                } catch (error) {
                    console.log(error);
                }
            }
            
          
           
        }

        
    }
}
</script>

<style>
    .activeTabMen{
        @apply  text-secondary  border-b-2 border-secondary;
    }
</style>